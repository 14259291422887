import React from "react";
import Layout from "../other/layout";
import FoireAuxQuestions from "../components/AC-StaticPages/FoireAuxQuestions";
import SEO from "../other/seo";

export default function foireAuxQuestions() {
  return (
    <Layout>
      <SEO title="Foire aux questions" />

      <FoireAuxQuestions />
    </Layout>
  );
}
