import React from "react";
import styles from "./styles.module.css";

export default function AProposDeNebs() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Foire aux questions</h1>
        <h2>Comment puis-je passer une commande ?</h2>
        <p>Choisissez l'un ou l'autre de ces moyens faciles !</p>
        <p>
          En ligne : servez-vous du catalogue internet pour passer votre
          commande.
          <br />
          Par téléphone : 1-800-461-9666, du lundi au vendredi, entre 8 h et
          17h30 HNE.
          <br />
          Par télécopieur : 1-800-461-3325, 24 heures par jour, tous les jours.
        </p>
        <h2>Comment puis-je payer ma commande ?</h2>
        <p>Voici toutes les options de paiement que nous vous offrons !</p>
        <p>Crédit : Crédit immédiat de 1 000 $, paiement net en 30 jours.</p>
        <p>
          Par chèque : Vous pouvez payer votre commande par chèque ou mandat.
          Vous n'avez qu'à joindre votre chèque ou mandat à votre facture.
        </p>
        <p>
          Par carte de crédit : Nous acceptons les cartes VISA, Mastercard et
          American Express. Nous porterons à votre compte le montant de l'achat
          au moment d'expédier votre commande, ce qui prend habituellement 3 à 5
          jours ouvrables. Certaines commandes sont plus longues à traiter,
          comme celles de vêtements.
        </p>
        <p>
          Tous les achats en ligne par carte de crédit sont sécurisés. Pour plus
          de renseignements au sujet de ce que nous faisons pour assurer la
          sécurité de vos transactions, consultez nos politiques de
          confidentialité et de sécurité.
        </p>
        <p>
          Prépaiement : Un paiement anticipé intégral accélérera le traitement
          de votre commande par notre service du crédit et établira votre crédit
          pour vos prochaines commandes.
        </p>
        <p>Désolés, nous n'acceptons pas les commandes C.O.D.</p>
        <h2>Dans combien de temps recevrai-je ma commande ?</h2>
        <p>Vous recevrez rapidement ce dont vous avez besoin !</p>
        <p>
          Tous les articles stockés (non surimprimés) sont expédiés en seulement
          24 heures. La plupart des articles surimprimés standards sont expédiés
          en 3 jours ouvrables. La plupart des cadeaux d'entreprises et
          vêtements sont expédiés en 10 jours ouvrables. Prévoyez du temps
          additionnel pour la livraison.
        </p>
        <p>
          Vous en avez besoin plus tôt que cela ? À votre demande, nous
          expédierons n'importe quel produit Deluxe par le service Xpresspost
          (livraison dans les 2 jours ouvrables suivants) ou Messageries
          prioritaires (livraison le jour ouvrable suivant) de Postes Canada,
          plus le temps de traiter la commande. Nous ajouterons à votre facture
          les frais d'expédition applicables.
        </p>
        <p>
          Pour plus de renseignements sur les modalités d'expédition et de
          livraison, faites-nous parvenir un courriel à l'adresse
          cdncustserv@nebs.com, ou appelez notre service à la clientèle au
          1-800-461-9666, du lundi au vendredi, entre 8 h et 17h30 HNE.
        </p>
        <h2>Qui puis-je appeler si j'ai besoin d'aide avec ma commande ?</h2>
        <p>Nos conseillers sont toujours prêts à vous aider !</p>
        <p>
          Nos conseillers chevronnés sont les spécialistes de nos produits et
          ils savent comment ceux-ci peuvent répondre à vos besoins. Vous n'avez
          qu'à composer le 1-800-461-9666 pour les joindre. Peu importe votre
          question, nous avons la réponse !
        </p>
        <p>
          Bien sûr, vous pouvez nous envoyer un courriel à commande@nebs.com.
          Vous pouvez aussi appeler pour savoir où en est le traitement d'une
          commande déjà passée. Si vous avez un problème avec une commande
          antérieure, nous vous promettons de faire l'impossible pour rectifier
          le tout. Vous n'avez qu'à appeler notre service à la clientèle au
          1-800-461-9666, du lundi au vendredi, entre 8 h et 17h30 HNE.
        </p>
        <h2>Que faire si je suis insatisfait de ma commande ?</h2>
        <p>Nous ferons l'impossible pour rectifier le tout !</p>
        <p>
          Si un produit Deluxe ne vous satisfait pas entièrement pour quelque
          raison que ce soit, vous obtiendrez de notre part un crédit complet,
          un remplacement ou un remboursement incluant tous les frais
          d'expédition que vous auriez payés. Tout ce que vous avez à faire est
          d'appeler notre service à la clientèle au 1-800-461-9666, du lundi au
          vendredi, entre 8 h et 17h30 HNE.
        </p>
        <h2>Pouvez-vous imprimer le logo de mon entreprise ?</h2>
        <p>Absolument !</p>
        <p>
          Si vous désirez que nous imprimions le logo de votre société sur le
          produit commandé, voici la marche à suivre :
        </p>
        <ol>
          <li>
            <h3>étape 1.</h3>
            <p>Envoyez-nous le montage à ..</p>
            <p>
              À noter : avant d'envoyer le fichier par courriel, n'oubliez pas
              d'indiquer à la ligne objet votre numéro de commande et votre
              numéro de téléphone.
            </p>
          </li>
          <li>
            <h3>étape 2.</h3>
            <p>
              Télécopiez-nous au 1-800-461-3325 une copie papier du montage que
              nous pourrons jumeler avec le fichier envoyé par courriel.
            </p>
          </li>
          <li>
            <h3>étape 3.</h3>
            <p>
              Envoyez-nous par la poste un échantillon imprimé avec le disque à
              l'adresse suivante :
              <br />
              Produits d'Affaires Deluxe limitée
              <br />
              330 Cranston Crescent
              <br />
              Midland (Ontario) L4R 4V9
            </p>
          </li>
        </ol>
        <h2>Formats</h2>
        <p>
          Le procédé que Deluxe utilise pour créer ses produits nécessite un
          format d'illustration en dessin au trait (line art) issu d'un logiciel
          de graphisme vectoriel. Voici des indications sur les formats de
          fichiers acceptés et refusés afin que vous puissiez nous envoyer par
          courriel ou par disque un montage que nous serons en mesure de traiter
          pour reproduire votre logo.
        </p>
        <p>
          <span>Formats acceptés</span> : Adobe Illustrator (version 9 ou
          antérieure), Corel Draw (version 8 ou antérieure) À noter : tout le
          texte doit être converti en courbes.
        </p>
        <p>
          <span>Formats refusés</span> : Images en mode point : BMP, TIFF, GIF,
          JPEG et PCX. NE sauvegardez PAS ces formats en fichiers Corel Draw ou
          Illustrator, car les images resteront en mode point. N'envoyez pas non
          plus de document issu d'un logiciel de traitement de texte (DOC, WPD).
          Il est impossible de convertir ces types de fichiers en dessin au
          trait.
          <br />
          Veuillez vous assurer de nous envoyer tout montage en dessin au trait
          sous l'un ou l'autre des formats acceptés mentionnés plus haut. Si
          vous n'êtes pas en mesure de le faire, postez-nous un exemplaire
          imprimé d'excellente qualité de votre montage et nous le reproduirons.
        </p>
        <p>
          <span>Polices</span> : Nous ne pouvons vous garantir une concordance
          parfaite si vous avez utilisé une police très particulière ou
          inhabituelle. Si nous ne parvenons pas à reproduire une police avec
          exactitude, nous y substituerons un type de caractères similaire.
        </p>
        <p>
          Nous serons heureux d'ajouter votre logo ou votre illustration aux
          produits commandés.
        </p>
        <h2>Les produits Deluxe sont-ils tous offerts en ligne ?</h2>
        <p>
          Nous offrons sur internet les produits qui se vendent le mieux. Si
          vous ne trouvez pas ce que vous cherchez, contactez-nous au
          1-800-461-9666. Nous nous ferons un plaisir de créer le produit dont
          vous avez besoin. Merci pour votre patience en attendant que nous
          puissions vous offrir davantage de produits sur notre site internet !
        </p>
        <h2>Dois-je ouvrir une session pour commander en ligne ?</h2>
        <p>
          Pas nécessaire ! Vous n'avez qu'à accéder à notre catalogue
          électronique à partir de la Page d'accueil, choisir le ou les produits
          qui vous intéressent, prendre connaissance des spécifications pour la
          surimpression, cliquer sur « ajouter au panier » et sortir !
        </p>
      </div>
    </div>
  );
}
